@font-face {
  font-family: Fira Code;
  src: url("FiraCode-Light.46744d42.woff2") format("woff2"), url("FiraCode-Light.14c740e8.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Fira Code;
  src: url("FiraCode-Regular.0466dc67.woff2") format("woff2"), url("FiraCode-Regular.632507b9.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Fira Code;
  src: url("FiraCode-Medium.c28f373b.woff2") format("woff2"), url("FiraCode-Medium.58e978c3.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Fira Code;
  src: url("FiraCode-SemiBold.d5ffb8f3.woff2") format("woff2"), url("FiraCode-SemiBold.350adc12.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Fira Code;
  src: url("FiraCode-Bold.b74e091a.woff2") format("woff2"), url("FiraCode-Bold.380a68bb.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Fira Code VF;
  src: url("FiraCode-VF.81f6d435.woff2") format("woff2-variations"), url("FiraCode-VF.4b57d44b.woff") format("woff-variations");
  font-weight: 300 700;
  font-style: normal;
}
/*# sourceMappingURL=index.09c58b5b.css.map */
